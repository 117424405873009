<template>
    <div class="max-w-7xl mx-auto mb-5">
        <div class="relative  py-4">
            <div class="absolute inset-0">
                <div class="h-1/3 sm:h-2/3"></div>
            </div>
            <div class="relative max-w-7xl mx-auto">
                <div class="text-center">
                    <h2 class="text-3xl leading-9 tracking-tight font-extrabold text-white sm:text-4xl sm:leading-10">
                        Apple Podcasts
                    </h2>
                </div>

                <div class="mt-12 flex overflow-scroll scrolling-touch gap-2 mx-auto w-full cursor-pointer">
                    <div v-for="(podcast, index) in podcasts" :key="index" @click="openLink(podcast.trackViewUrl)"
                         class="flex flex-col mx-1 mb-10 flex-none w-1/2 sm:w-1/3 sm-w-48 md:w-64 xl:w-80 rounded-app overflow-hidden">
                        <div class="flex-shrink-0" >
                            <div  class="h-48 sm:h-48 md:h-48 lg:h-64 xl:h-80 w-full bg-contain bg-center overflow-hidden"
                                  :style="{backgroundImage: `url(${httpsLink(podcast.artworkUrl600)})`, 'background-color': '#cccccc', }">

                                <div class="w-full h-full items-end flex overflow-hidden">
                                    <div :style="{backdropFilter: 'blur(5px)'}"
                                         class="flex-1 bg-white px-6 py-3 sm:px-6 sm:py-3 lg:p-6   truncate flex flex-col justify-between bg-opacity-75">
                                        <div class="flex-1">
                                            <div class="text-sm leading-5 font-medium text-indigo-600">
                                                <div class="">
                                                    Apple Podcasts
                                                </div>
                                            </div>
                                            <div class="block">
                                                <p class=" md:mt-2 sm:text-sm md:text-md lg:text-xl sm:leading-2 md:leading-7 font-semibold text-gray-900 w-full truncate">
                                                    {{ podcast.trackName }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import {httpsLink} from '../utils/helpers'
    export default {
        name: "SearchEnginePodcasts",
        props: ['podcasts'],
        methods: {
            openLink(link){
                window.open(this.httpsLink(link), '_blank');
            },
            httpsLink: httpsLink,
        },
        computed: {
        }
    }
</script>

<style scoped>

</style>
