<template>
    <router-link tag="div" :to="`/${community.owner.username}/${communityUrl(community.name)}`" class="flex flex-col transition duration-150 ease-in-out cursor-pointer hover:bg-gray-200">
        <div class="p-2 sm:p-4 flex sm:flex-row flex-col sm:px-6 items-center items-stretch">
            <div class="sm:w-1/3 flex sm:flex sm:items-center">
                <div>
                    <div class="text-md leading-5 font-medium text-indigo-600 text-left flex flex-wrap">
                        {{ getNameWithSpace(community.name) }}
                        <span v-if="showUsername" class="ml-1 font-normal text-gray-500">
                            by
                            <router-link :to="`/${community.owner.username}`" tag="button" class="hover:text-jiruto-main hover:font-medium">{{ community.owner.username }}</router-link>
                        </span>
                    </div>
                    <div class="mt-2 flex">
<!--                        <div class="flex items-center text-sm leading-5 ">-->
<!--                            <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">-->
<!--                                <path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd"/>-->
<!--                            </svg>-->
<!--                            <span>-->
<!--                                Last updated:-->
<!--                                <time datetime="2020-01-07">{{ dateFromInput(community.lastUpdated) }}</time>-->
<!--                            </span>-->
<!--                        </div>-->
                        <div v-if="community.stars_count != 0" class="flex items-center text-sm leading-5 ml-2">
                            <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                                <path fill-rule="evenodd" d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" clip-rule="evenodd"/>
                            </svg>
                            <span class="-ml-1">
                                {{ community.starsCount}}
                            </span>
                        </div>
                        <div class="h-5 w-5 text-gray-400">
                            <svg fill="currentColor" viewBox="0 0 20 20"><path ></path></svg>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex-grow w-full mt-4 lg:mt-0 sm:w-1/2 text-md leading-5 font-light text-gray-500 text-left mx-2 ">
                <div class="h-full text-fill flex items-center">
                    {{ community.description }}
                </div>
                <!--                        <p class="text-wrap h-full">{{ community.description }}</p>-->
                <!--                        <div class="text-left truncate"></  div>-->
            </div>
            <div v-if="isCommunityEmpty" class="ml-2 flex-shrink-0 flex items-center">
                            <div class="px-2 py-1 inline-flex text-md leading-5 font-semibold rounded-md bg-jiruto-flatRed text-white">
                                In Progress
                            </div>
            </div>
            <div class="hidden sm:block">
                <router-link :to="`/${community.full_name}`" class="ml-5 flex-shrink-0 items-center flex h-full">
                    <svg class="h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"/>
                    </svg>
                </router-link>
            </div>
        </div>
    </router-link>
</template>

<script>
    import {dateFromInput} from "../utils/helpers";

    export default {
        name: "CommunityRow",
        props: {
            community: {},
            showUsername: {
                default: true
            }
        },
        // props: ['community', 'showUsername'],
        computed: {
            isCommunityEmpty(){
                return (this.community.size <= 21)
            },
        },
        methods: {
            getNameWithSpace(str){
                return str.replace(/_-/g, " ")
            },
            communityUrl(str){
                return str.replace(/\s/g, "-")
            },
            dateFromInput,
        },

    }
</script>

<style scoped>

</style>
