<template>
    <div class="max-w-7xl mx-auto my-10">
        <div class="relative  py-4">
            <div class="absolute inset-0">
                <div class="h-1/3 sm:h-2/3"></div>
            </div>
            <div class="relative max-w-7xl mx-auto">
                <div class="text-center">
                    <h2 class="text-3xl leading-9 tracking-tight font-extrabold text-white sm:text-4xl sm:leading-10">
                        Courses
                    </h2>
                    <p class="mt-3 max-w-2xl mx-auto text-xl leading-7 text-gray-300 sm:mt-4">
                        Scroll to the side to see more
                    </p>
                </div>

                <div class="mt-12 flex overflow-scroll scrolling-touch gap-2 mx-auto w-full rounded-md">
                    <div v-for="(course, index) in courses" :key="index" @click="openLink(course.url)"
                         class="flex flex-col mx-1 mb-10 flex-none w-9/12 sm:w-8/12 md:w-5/12 xl:w-5/12 rounded-lg shadow-lg overflow-hidden cursor-pointer">
                        <div class="flex-shrink-0">
                            <img class="h-48 w-full object-cover" :src="httpsLink(course.image_480x270)" alt="" />
                        </div>
                        <div class="flex-1 bg-white p-6 flex flex-col justify-between">
                            <div class="flex-1">
                                <div class="text-sm leading-5 font-medium text-indigo-600">
                                    <div class="">
                                        Udemy
                                    </div>
                                </div>
                                <div class="block ">
                                    <h3 class="mt-2 text-xl leading-7 font-semibold text-gray-900">
                                        {{ course.title }}
                                    </h3>
                                    <p class="mt-3 text-base leading-6 text-gray-500">
                                        {{ course.headline }}
                                    </p>
                                </div>
                            </div>
                            <div class="mt-6 flex items-center">
                                <div class="flex-shrink-0">
                                    <a href="#">
                                        <img class="h-10 w-10 rounded-full" :src="httpsLink(course.visible_instructors[0].image_100x100)" alt="" />
                                    </a>
                                </div>
                                <div class="ml-3">
                                    <div class="text-sm leading-5 font-medium text-gray-900">
                                        <div class="">
                                            {{course.visible_instructors[0].display_name}}
                                        </div>
                                    </div>
                                    <div class="flex text-sm leading-5 text-gray-500">
                                        <time datetime="2020-03-16">
                                            Mar 16, 2020
                                        </time>
                                        <span class="mx-1">
                                              &middot;
                                        </span>
                                        <span>
                                            6 min read
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>



</template>

<script>
    import {httpsLink} from '../utils/helpers'
    export default {
        name: "SearchEngineCourses",
        props: ['courses'],
        methods: {
            openLink(link){
                window.open(this.httpsLink(link), '_blank');
            },
            httpsLink: httpsLink,
        },
        computed: {
        }
    }
</script>

<style lang="stylus" scoped>

</style>
