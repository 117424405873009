<template>
    <div>
        <div class="text-center">
            <h2 class="text-3xl leading-9 tracking-tight font-extrabold text-white sm:text-4xl sm:leading-10">
                Books
            </h2>
        </div>
        <div class="row">
            <div class="row__inner">
                <div @click="openLink(book.volumeInfo.canonicalVolumeLink)" v-for="(book, index) in books" :key="index" class="tile">
                    <div class="tile__media">
                        <img class="tile__img" :src="httpsLink(book.volumeInfo.imageLinks.thumbnail)" alt=""  />
                    </div>

                    <div class="tile__details flex items-end">
                        <div class="tile__title text-white flex items-center justify-left truncate overflow-hidden">
                            <p class="overflow-hidden truncate text-center text-lg">
                                {{ book.volumeInfo.title }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</template>

<script>
    import {httpsLink} from '../utils/helpers'

    export default {
        name: "SearchEngineBooks",
        props: ['books', 'type'],
        methods: {
            openLink(link){
                const secureLink = this.httpsLink(link)
                window.open(secureLink, '_blank');
            },
            httpsLink: httpsLink,
        },
    }
</script>

<style lang="stylus" scoped>

    @media screen and (min-width 700px) and (max-width 1399px)
        width 2.5em




    $duration = 450ms
    $tileWidth = 200px

    @media screen and (min-width 700px) and (max-width 1399px)
        $tileWidth = 200px

    @media screen and (min-width 1400px) and (max-width 2099px)
        $tileWidth = 200px


    $tileHeight = ($tileWidth * 1.5)
    $iconSize = 50px
    $growFactor = 1.5 // 1.5 times the original size

    $moveLeft = -($tileWidth * ($growFactor - 1) / 2)
    $moveRight = $tileWidth * ($growFactor - 1)

    body, html
        padding: 0 10px
        margin: 0
        background: #0E0F11
        color: #ecf0f1
        font-family: 'Open Sans', sans-serif
        min-height: 100vh
        display: flex
        flex-direction: column
        align-items: center
        width: 100%

    * { box-sizing: border-box }

    h1, p
        text-align: center

    p
        width: 100%
        max-width: 500px
        margin: auto

    a:link
    a:hover
    a:active
    a:visited
        transition: color 150ms
        color: #95a5a6
        text-decoration: none

    a:hover
        color: #7f8c8d
        text-decoration: underline

    .contain
        width: 100%

    .row
        overflow: scroll
        width: 100%

    .row__inner
        transition: $duration transform
        font-size: 0
        white-space: nowrap
        margin: 50px 0
        padding-bottom: 10px // Account for OS X scroll bar

    .tile
        position: relative
        display: inline-block
        width: $tileWidth
        height: $tileHeight
        margin-right: 5px
        margin-left: 5px
        font-size: 20px
        cursor: pointer
        transition: $duration all
        transform-origin: center left

    .tile__img
        width: $tileWidth
        height: $tileHeight
        object-fit: cover

    .tile__details
        position: absolute
        bottom: 0
        left: 0
        right: 0
        top: 0
        font-size: 10px
        opacity: 0
        background: linear-gradient(to top, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 100%)
        transition: $duration opacity



        &:before
            content: ''
            position absolute
            top: 50%
            left: 50%
            display: black

        &:after
            margin-top: (-($iconSize / 2))
            margin-left: (-($iconSize / 2))
            width: $iconSize
            height: $iconSize
            border: 3px solid #ecf0f1
            line-height: $iconSize
            text-align: center
            border-radius: 100%
            background: rgba(0,0,0,0.5)
            z-index: 1

        &:before
            left: 0
            width: 100%
            font-size: 30px
            margin-left: 7px
            margin-top: -18px
            text-align: center
            z-index: 2

        .tile:hover &
            opacity: 1

    .tile__title

        bottom: 0
        padding: 10px
        color: white
        stroke: white
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap


    // *
    // * Hover effects
    // *

    // We assume when you hover .row, you're also hovering a .tile
    // First, move the whole row towards the left


    .row__inner:hover .tile

        // Fade out every tile
        opacity: .3

        // And then grow the current tile and make it opaque again
        &:hover
            opacity: 1

    // And finally move all tiles right of the current tile to the right,
    // so its flush against the hovered tile



</style>
