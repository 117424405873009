<template>
    <div class="flex flex-col">
        <div class="border p-5 rounded-md" :class="{'order-first': communitiesExist, 'order-last': !communitiesExist}">
            <div class="flex justify-center items-center content-center mt-4">
                <div class="flex  rounded-md  p-3 w-7/12 text-center justify-center content-center items-center">
                    <h1 class="underline text-2xl text-white lg:text-5xl leading-none">Jiruto Learning Communities</h1>
                </div>
            </div>

            <div v-if="explore.communities != null && !communitiesEmpty" class="mt-20">
                <router-link v-for="(community, index) in explore.communities" :key="index" tag="div" :to="`/${community.name}`" class="w-full px-4 text-center bg-white my-5 rounded-md hover:bg-gray-100 focus:outline-none focus:bg-gray-100 cursor-pointer" >
                    <communityRow :community="community"/>
                </router-link>
                <div v-if="explore.searchEngine">
                    <div class="justify-center flex justify-center items-center rounded-md">
                        <div class="flex justify-center bg-gray-300 px-3 py-2 items-center rounded-md ">
                            <h1 class="text-center lg:text-left text-3xl sm:text-5xl font-bold text-indigo-500 leading-tight">Scroll down to view more content!</h1>
                        </div>
                    </div>
                    <div class="flex justify-center text-jiruto-zotSecondary">
                        <svg fill="currentColor" viewBox="0 0 20 20" class="h-20 w-20"><path fill-rule="evenodd" d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                    </div>
                </div>

            </div>

            <div class="py-5" v-if="explore.communities != null && communitiesEmpty">
                <emptyExploreCTA CTAtype="communities"></emptyExploreCTA>
<!--                <div v-if="communitiesExist">-->
<!--                    <div  class="justify-center flex justify-center items-center rounded-md">-->
<!--                        <div class="flex justify-center bg-gray-300 px-3 py-2 items-center rounded-md ">-->
<!--                            <h1 class="text-center lg:text-left text-3xl sm:text-5xl font-bold text-indigo-500 leading-tight">Scroll down to view more content!</h1>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="flex justify-center text-jiruto-zotSecondary">-->
<!--                        <svg fill="currentColor" viewBox="0 0 20 20" class="h-20 w-20"><path fill-rule="evenodd" d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>-->
<!--                    </div>-->
<!--                </div>-->

            </div>
        </div>


        <div v-if="explore.showSocial" class=" border rounded-md my-10 order-1">

            <div class="flex justify-center items-center content-center my-10">
                <div class="flex bg-gray-200 rounded-md bg-jiruto-zotDarker p-3 w-7/12 text-center justify-center content-center items-center">
                    <h1 class="text-2xl text-white lg:text-5xl leading-none">From Around The Web</h1>
                    <spinner class="ml-5" v-if="searching"/>
                    <div class="loader"/>
                </div>
            </div>

            <div v-if="explore.searchEngine" class="mt-10">
                <div v-if="explore.searchEngine.courses.length > 0" class="mt-5">
                    <search-engine-courses :courses="explore.searchEngine.courses"/>
                </div>

                <div v-if="explore.searchEngine.books.length > 0" class="mt-5 lg:mt-10">
                    <search-engine-books :books="explore.searchEngine.books"/>
                </div>

                <div v-if="explore.searchEngine.podcasts.length > 0" class="mt-5 lg:mt-10">
                    <search-engine-podcasts :podcasts="explore.searchEngine.podcasts"/>
                </div>

                <div v-if="explore.searchEngine.apps.length > 0" class="mt-5 lg:mt-10">
                    <search-engine-apps :apps="explore.searchEngine.apps"/>
                </div>

            </div>

        </div>



    </div>
</template>

<script>
    import {dateFromInput} from "@/utils/helpers";
    import CommunityRow from "../components/CommunityRow"
    import EmptyExploreCTA from '../components/EmptyExploreCTA'
    import SearchEngineBooks from "./SearchEngineBooks";
    import SearchEngineCourses from "./SearchEngineCourses";
    import spinner from "./spinner"
    import SearchEngineApps from "./SearchEngineApps";
    import SearchEnginePodcasts from "./SearchEnginePodcasts";



    export default {
        name: "ExploreCommunities",
        props: ['explore', 'searching', 'showSocial'],
        components: {
            SearchEnginePodcasts,
            SearchEngineApps,
            SearchEngineBooks,
            SearchEngineCourses,
            spinner,
            communityRow: CommunityRow,
            emptyExploreCTA: EmptyExploreCTA,
        },
        methods: {
            getNameWithSpace(str){
                return str.replace(/_-/g, " ")
            },
            dateFromInput,
        },
        computed: {
            communitiesEmpty() {
                return (this.explore.communities.length === 0)
            },
            communitiesExist(){
                return (this.explore.communities != null && !this.communitiesEmpty)
            }
        }
    }
</script>

<style scoped>

</style>
