<template>
    <div class="max-w-7xl mx-auto sm:px-6 lg:px-8 my-10">

        <div class=" my-10 flex">
            <div class=" flex mx-auto px-4 sm:px-6 lg:px-8">
                <div class="px-6 py-6 bg-indigo-700 rounded-lg md:py-4 md:px-12 lg:py-4 lg:px-16 xl:flex xl:items-center xl:justify-between ">
                    <div class="">
                        <h2 class="text-2xl text-center leading-8 font-extrabold tracking-tight text-white sm:text-3xl sm:leading-9">
                            Want to add your own community??
                        </h2>
                    </div>
                    <div class="mt-3 sm:max-w-md xl:mt-0 xl:ml-8  ">
                        <div class="sm:flex flex justify-center">
                            <div class="mt-3 rounded-md sm:mt-0 sm:flex-shrink-0">
                                <router-link to="/newCommunity" class="text-center inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-jiruto-zotSecondary hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                                    Create a new community
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="mb-3">
            <div class="max-w-screen-xl mx-auto text-center py-6 px-4 sm:px-6 lg:py-6 lg:px-8">
                <h2 class="text-3xl leading-9 font-extrabold tracking-tight text-gray-200 sm:text-4xl sm:leading-10">
                    Explore Jiruto!
                </h2>
            </div>
        </div>

        <div class="flex-1 flex justify-center px-2">
            <div class=" w-full">
                <label for="exploreSearch" class="sr-only">Search</label>
                <div class="relative w-full ">
                    <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <svg class="h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                            <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                        </svg>
                    </div>
                    <input v-model="searchQuery" @keypress.enter="inputSearch" type="search" id="exploreSearch" class="block w-full pl-10 pr-3 py-2 border border-transparent rounded-md leading-5 bg-gray-100 placeholder-gray-400 focus:outline-none focus:bg-white sm:text-sm lg:text-lg transition duration-150 ease-in-out h-12  " placeholder="Search" />
                </div>
            </div>
        </div>

<!--        <div class="my-5 mx-2">-->
<!--            <div class="sm:hidden">-->
<!--                <select v-model="activeComponent" class="form-select block w-full">-->
<!--                    <option value="0" selected>Communities</option>-->
<!--                    <option value="1">Users</option>-->
<!--                </select>-->
<!--            </div>-->
<!--            <div class="hidden sm:block">-->
<!--                <div class="border-b border-gray-200">-->
<!--                    <nav class="-mb-px flex">-->
<!--                        <button @click="activeComponent = 0" class="w-1/2 py-4 px-1 text-center border-b-2 border-transparent font-medium text-sm leading-5 text-gray-200 hover:text-jiruto-zotSecondary hover:border-jiruto-zotSecondary focus:outline-none"-->
<!--                                :class="{'border-jiruto-zotSecondary text-jiruto-zotSecondary': activeComponent === 0}"-->
<!--                        >-->
<!--                            Communities-->
<!--                        </button>-->
<!--                        <button @click="activeComponent = 1"  class="w-1/2 py-4 px-1 text-center border-b-2 border-transparent font-medium text-sm leading-5 text-gray-200 hover:text-jiruto-zotSecondary hover:border-jiruto-zotSecondary focus:outline-none"-->
<!--                                :class="{'border-jiruto-zotSecondary text-jiruto-zotSecondary': activeComponent === 1}"-->
<!--                        >-->
<!--                            Users-->
<!--                        </button>-->
<!--                    </nav>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

        <div class="my-10 mx-2">
            <keep-alive>
                <component :is="components[activeComponent]" :explore="explore" :searching="searching"/>
            </keep-alive>
        </div>



    </div>
</template>

<script>
    import { EventBus } from '../jiruto-bus';
    // import _ from 'lodash';
    import {mapState} from 'vuex'
    import ExploreUsers from '../components/ExploreUsers'
    import ExploreCommunities from '../components/ExploreCommunities'
    import * as axios from '../axios-auth'
    export default {
        name: "Explore",
        props: {
            queryProp: {
                default: ""
            }
        },
        components: {
            exploreUsers: ExploreUsers,
            exploreCommunities: ExploreCommunities
        },
        data() {
            return {
                explore: {
                    users: null,
                    communities: null,
                    searchEngine: null,
                    showSocial: false,
                },
                loader: null,
                searchQuery: "",
                creationTime: null,
                components: [
                    'explore-communities',
                    'explore-users',
                ],
                activeComponent: 0,
                selectedComponent: "Communities",
                searching: false,
            }
        },
        created(){
            EventBus.$on('refreshExplore', this.refreshExplore)
            // this.creationTime.
            // this.searchQuery = this.queryProp;
            this.searchQuery = this.$route.query.q ?? ""
            this.search()
        },
        beforeDestroy() {
            EventBus.$off()
        },
        methods: {
            refreshExplore(){
                this.emptySearchQuery()
                this.search()
            },
            emptySearchQuery(){
                this.searchQuery = ""
            },
            searchEngine(){
                if(this.searchQuery === ""){
                    return
                }
                this.searching = true
                this.explore.searchEngine = null
                axios.jigo.get(`search/${this.searchQuery}`, {validateStatus: (status) => {
                        return status < 500
                    }
                }).then((resp) => {
                    this.searching = false
                    if (resp.status === 200){
                        this.explore.searchEngine = resp.data
                    }
                }).catch((err)=> {
                    this.searching = false
                    console.error("An error occurred trying to fetch search engine", err)
                })
            },
            replaceRoute(){
                if(this.searchQuery !== ""){
                    this.$router.replace({community: 'explore', query: {q: this.searchQuery}})
                }
            },
            inputSearch(){
                this.replaceRoute()
                this.search()
            },
            search() {
                this.explore.showSocial = (this.searchQuery.trim() !== "");
                this.searchEngine()
                let loader = this.$loading.show({
                    onCancel: this.loaderCanceled,
                });
                // const usersPromise =
                //     axios.jigit.get("/users/search/", {params: {q: this.searchQuery, includeDesc: true},
                //         validateStatus: (status) => {
                //             return status < 500
                //         }
                //     }).then((resp) => {
                //
                //         if (resp.status === 200) {
                //             this.explore.users = resp.data.data
                //             return Promise.resolve()
                //         } else {
                //             console.error("Some error occurred trying to fetch the users",resp.data)
                //             return Promise.reject(resp.data)
                //         }
                //     })
                //         .catch((err) => {
                //             console.error("Some error occurred trying to fetch the users", err)
                //             return Promise.reject(err)
                //         })


                // const communitiesPromise =
                //     axios.jigit.get("/repos/search", {params: {q: this.searchQuery, limit: 60, includeDesc: true, sort: "updated", order: "desc"},
                //         validateStatus: (status) => {
                //             return status < 500
                //         }
                //     })
                //         .then((resp) => {
                //             if (resp.status === 200) {
                //                 let dataArray = resp.data.data
                //                 this.explore.communities = _.shuffle(dataArray)
                //                 // this.explore.communities = dataArray
                //                 return Promise.resolve()
                //             } else {
                //                 console.error("Some error occurred trying to fetch the repos",resp.data)
                //                 return Promise.reject(resp.data)
                //             }
                //         })
                //         .catch((err) => {
                //             console.error("Some error occurred trying to fetch the repos", err)
                //             return Promise.reject(err)
                //         })

                const query = this.searchQuery.length < 1 ? " " : this.searchQuery
                const communitiesPromise =
                    axios.jigo.get("/v2/communities/search", {params: {q: query, limit: 60, includeDesc: true, sort: "updated", order: "desc"},
                        validateStatus: (status) => {
                            return status < 500
                        }
                    })
                        .then((resp) => {
                            if (resp.status === 200) {
                                // let dataArray = resp.data.data
                                this.explore.communities = resp.data
                                return Promise.resolve()
                            } else {
                                console.error("Some error occurred trying to fetch the repos",resp.data)
                                return Promise.reject(resp.data)
                            }
                        })
                        .catch((err) => {
                            console.error("Some error occurred trying to fetch the repos", err)
                            return Promise.reject(err)
                        })


                // return Promise.all([usersPromise, communitiesPromise])
                return Promise.all([communitiesPromise])
                    .then(() => {
                        loader.hide()
                    })
                    .catch((err) => {
                        loader.hide()
                        console.error("Something failed in explore loading", err)
                    })
            }
        },
        computed: {
            ...mapState(['searchTerm']),
        },
        watch: {
            searchTerm(newVal){
                this.searchQuery = newVal
                this.search()
            },
            queryProp(){
                this.searchQuery = this.queryProp
                this.search()
            }
        }
    }
</script>

<style scoped>

</style>
