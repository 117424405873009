<template>
    <div>
        <div v-if="explore.users != null && !usersEmpty">
            <users-grid :users="explore.users"></users-grid>
        </div>
        <div v-if="explore.users != null && usersEmpty">
            <empty-explore-CTA CTAtype="users"></empty-explore-CTA>
        </div>
    </div>


</template>

<script>
    import usersGrid from '../components/UsersGrid'
    import EmptyExploreCTA from '../components/EmptyExploreCTA'
    import {dateFromInput} from "../utils/helpers";

    export default {
        name: "ExploreUsers",
        props: ['explore'],
        components: {
            usersGrid,
            emptyExploreCTA: EmptyExploreCTA,
        },
        methods: {
            getNameWithSpace(str){
                return str.replace(/_-/g, " ")
            },
            dateFromInput,
        },
        computed: {
            usersEmpty() {
                return (this.explore.users.length === 0)
            }
        }
    }
</script>

<style scoped>

</style>
