<template>
    <div class="flex flex-wrap my-10">
        <div v-for="(user, index) in users" :key="index" class="w-full md:w-4/12 px-4 text-center">
            <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg" :class="bgColor">
                <div class="px-4 py-5 flex-auto">
                    <div class="text-white text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                        <img :src="user.avatar_url" alt="" class="rounded-full">
                    </div>
                    <h6 class="text-xl font-semibold">{{ user.username }}</h6>
                    <!--                    <p class="mt-2 mb-4 text-gray-600">-->
                    <!--                        Keep you user engaged by providing meaningful information.-->
                    <!--                        Remember that by this time, the user is curious.-->
                    <!--                    </p>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "UsersGrid",
        // props: ['users','bg-color'],
        props: {
            users: {
                required: true,
            },
            bgColorProp: {
                default: 'bg-white',
            }
        },
        created(){
            this.bgColor = this.bgColorProp
        },
        data(){
            return {
                bgColor: 'bg-white'
            }
        }
    }
</script>

<style scoped>

</style>
